import React from 'react'
import renderHTML from 'react-render-html'

class AuctionList extends React.Component {    
    constructor(props) {
        super();
        this.state = {
            param: ''
        }
    }

    componentDidMount(){
        console.log("AuctionList mount:");
    }
    
    init = (param) => {
        console.log("AuctionList init:" + param);
        this.setState({param: param});
    }

    render(){
        const {param} = this.state;
        let auctionUpcoming = this.props.auctionUpcoming;
        let auctionPast = this.props.auctionPast;
        let textAuctionList = ''
        let applicationSettingList = this.props.applicationSettingList;
        if (applicationSettingList) {
            applicationSettingList.map(async function (row) {
                if (row.setting_type === "TEXT_AUCTION_LIST") {
                    textAuctionList = row.value1;
                }
            });
        }


        return(
            <div id="standard-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="standard-modalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg modal-dialog-centered">
                    <div className="modal-content border">
                        {/* <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#login-modal').modal('hide')}>×</button>
                        </div> */}
                        <div className="modal-body" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
                            <div className="mr-2 mt-1">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#login-modal').modal('hide')}>×</button>
                            </div>
                            {param === '' &&
                                <>
                                    <p className="text-center mt-3">
                                        <img src="/assets/images/logo-county-tax-sales-full-spell-dark.png" alt="" height="40" />
                                    </p>
                                    <div className="text-muted pt-2 ml-2 text-center">
                                        {renderHTML(textAuctionList)}
                                    </div>
                                    <br />
                                </>
                            }
                            
                            <h4 className="modal-title pt-0 pl-2 pb-4">Upcoming Auctions</h4>
                            {this.renderCommon(auctionUpcoming, 'upcoming')}

                            {param === '' &&
                                <>
                                    <h4 className="modal-title pl-2 pb-4">Past Auctions</h4>
                                    {this.renderCommon(auctionPast, 'past')}
                                </>
                            }
                            
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>


        );
    }

    renderCommon(auctionList, actionType) {
        return (
            <table className="table table-hover table-centered mb-0">
                <thead>
                    <tr>
                        <th>Auction Dt</th>
                        <th style={{textAlign:"center"}}>Type</th>
                        <th>County</th>
                        <th>Properties</th>
                    </tr>
                </thead>
                <tbody>
                    {auctionList.length > 0 ?
                        <>
                            {auctionList.map((row, index) => {
                                let badge = "badge lien p-1";
                                if (row.type === "DEED") {
                                    badge = 'badge deed p-1';
                                }
                                let is_dt_of_data_load = false;
                                let is_auction_item_cnt = false;
                                if((row.auction_item_cnt === null || row.auction_item_cnt === 0) && row.dt_of_data_load){
                                    is_dt_of_data_load = true;
                                }
                                if(row.auction_item_cnt === null || row.auction_item_cnt === 0){
                                    is_auction_item_cnt = true;
                                }

                                let cntHtml = '';
                                if(row.auction_item_cnt){
                                    cntHtml =  <div>
                                                    <strong>{row.auction_item_cnt}</strong>
                                                    <p style={{ fontSize: '9pt' }}>Properties</p>
                                               </div>
                                }


                                return <tr key={index}
                                    onClick={() => this.props.onModalRowClick(row, is_dt_of_data_load, row.dt_of_data_load, is_auction_item_cnt, actionType)}
                                    style={{cursor:"pointer"}}>
                                    <td className="pr-0">{row.dt_of_auction}</td>
                                    <td className="pr-0" style={{textAlign:"center"}}>
                                        <span className={badge}>{row.type}</span>
                                        {
                                            (row.type === 'LIEN') ?
                                            <>
                                                <p style={{ fontSize: '9pt' }}>10-25% return/yr</p>
                                            </>
                                            :
                                            <span></span>
                                        }
                                    </td>
                                    <td className="pr-0">
                                        {row.name}, {row.state}
                                        {
                                            row.countytowns &&
                                            <p style={{ fontSize: '9pt' }}>{row.countytowns}</p>
                                        }
                                    </td>
                                    <td className="pr-0">
                                        {
                                            is_dt_of_data_load &&
                                            <>
                                                <span>List avail on</span><br />
                                                {row.dt_of_data_load}<br />
                                            </>
                                        }
                                        {cntHtml}
                                    </td>
                                </tr>
                            })}
                        </>
                        :
                        <tr>
                            <td colSpan={4}>
                                {/* There are no {actionType} auctions. Please <a className="bottomLink text-blue" onClick={() => this.props.handlerSocialModal()}>sign up</a> to be notified when an auction is added. */}
                                There are currently no {actionType} auctions. Many counties have postponed their auctions due to <span style={{color:'red'}}>COVID19</span>. <br />Please <a className="bottomLink text-blue" onClick={() => this.props.handlerSocialModal()}>sign up</a> to be notified when an auction is added.
                            </td>
                        </tr>
                    }
                </tbody>
            </table>

        )
    }

}


export default AuctionList;