
export function getServerUrl() {
    //return "http://countytaxsales.com:5003/api"; //remote
    return "https://countytaxsales.com/api"; //remote https
    //return "http://3.20.77.225:5005/api"; //dev
    //return "http://localhost:5005/api"; //local
}

export function getSiteUrl() {
  return "https://countytaxsales.com"; //remote
  //return "http://3.20.77.225:8087"; //dev
  //return "http://localhost:8087"; //local
}

export function googleKey(){
  //return "AIzaSyC_w7dAxREriahe44lAYzcBRAdirjeG_4c" // dev
  return "AIzaSyCUl2lkLYM1ANmw1ijC4512wmTKpmNNFqo"; //prod
}

export function googleClientId(){
  //return "410513351928-5bngcak8teigsaodbnmo9r1bishden7r.apps.googleusercontent.com" // dev
  return "665397203398-cu1b7i1mrhtlj6otnp1fbsb7iablhedv.apps.googleusercontent.com"; //prod
}

