import React from 'react'

export function currencyFormat(num) {
  if (num) {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return num;
}

export function currencyFormat2(num) {
  if (num) {
    return '$' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return num;
}


export async function setMemberID(user: User) {
  //console.log(user)
  localStorage.setItem("user", JSON.stringify(user));
}

export function getMember(): User {
  let obj: User;
  let user = localStorage.getItem("user");
  if (user) {
    obj = JSON.parse(user);
  }
  return obj;
}

export function getMemberID(): number {
  let memberID = -1;
  let user = localStorage.getItem("user");
  if (user) {
    let obj: User = JSON.parse(user);
    memberID = parseInt(obj.member_id.toString());
  }
  return memberID;
}

export function logout() {
  localStorage.removeItem("user");
}

export interface User {
  member_id: number;
  name: string;
  email: string;
  status: string;
  api_key_id: string;
  refer_shortname: string;
}

export function emailDone(email) {
  return (
    <>
      <p className="text-center pt-3">
        <i className="fas fa-envelope-open-text text-success" style={{ fontSize: "2.5em" }}></i>
      </p>
      <p className="text-center font-weight-bold">Success!</p>
      <p className="text-center font-weight-bold">
        An email has been sent to {email}.
    </p>
      <p className="text-center font-weight-bold">
        Please click on the link sent to log into your account.
    </p>
      <p className="text-center font-weight-bold text-muted">
        If you do not see the email in the next few mins, please
      check your<br /> spam. Mark the email as "not spam" to get our
      future emails.
    </p>

    </>
  )
}

export async function setIntroJs() {
  //console.log(user)
  localStorage.setItem("IntroJs", true);
}

export function getIntroJs() {
  let obj = false;
  let val = localStorage.getItem("IntroJs");
  if (val) {
    obj = val;
  }
  return obj;
}

export async function setReferShortName(value) {
  localStorage.setItem("refer_shortname", value);
}

export function getReferShortName() {
  let obj = false;
  let val = localStorage.getItem("refer_shortname");
  if (val) {
    obj = val;
  }
  return obj;

}

export function clearReferShortName() {
  localStorage.removeItem("refer_shortname");
}